var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.title"))+" ")]),_c('CCardBody',[_c('DataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.items,"fields":_vm.fields,"noItemsView":{
        noResults: _vm.$t('admin.no_results'),
        noItems: _vm.$t('admin.no_items')
      },"border":"","striped":"","hover":""},on:{"update:items":_vm.updateOrder},scopedSlots:_vm._u([{key:"show_sections",fn:function({item}){return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm","to":{
              name: 'accountant.restaurants.dishes',
              params: { restaurant: item.id }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.restaurants.go"))+" ")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }