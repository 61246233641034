<template>
  <CCard>
    <CCardHeader>
      {{ $t("admin.restaurants.title") }}

    </CCardHeader>
    <CCardBody>
      <DataTable
        :loading="isLoading"
        :items="items"
        :fields="fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        @update:items="updateOrder"
        border
        striped
        hover
      >

        <template #show_sections="{item}">
          <td class="py-2 text-center">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              :to="{
                name: 'accountant.restaurants.dishes',
                params: { restaurant: item.id }
              }"
            >
              {{ $t("admin.restaurants.go") }}
            </CButton>
          </td>
        </template>

      </DataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  },

  data() {
    return {
      isLoading: true,
      itemsPerPage: 10,
      items: [],
      fields: [
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "name", label: this.$t("admin.name") },
        {
          key: "show_sections",
          label: this.$t("admin.restaurants.sections.dishes.title")
        }
      ]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.$http.get("/api/accountant/restaurants").then(response => {
        this.items = response.data.data;
        this.isLoading = false;
      });
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.restaurants.title")
    };
  }
};
</script>
