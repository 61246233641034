<template>
  <CCard>
    <CCardHeader>
      {{ $t("admin.restaurants.sections.dishes.title") }}
    </CCardHeader>
    <CCardBody>
      <DataTable
        :loading="isLoading"
        :items="items"
        :fields="fields"
        :noItemsView="{
          noResults: $t('admin.no_results'),
          noItems: $t('admin.no_items')
        }"
        border
        striped
        hover
      >
      </DataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  },

  data() {
    return {
      isLoading: true,
      periods: [],
      periodsFields: [],
      items: []
    };
  },

  created() {
    this.getData();
  },

  computed: {
    fields() {
      return [
        { key: "section", label: this.$t("admin.category") },
        { key: "id", label: "ID", _style: "width: 100px" },
        {
          key: "article_id",
          label: this.$t("admin.restaurants.sections.dishes.article_id")
        },
        { key: "name", label: this.$t("admin.name") },
        { key: "price_buy", label: this.$t("admin.price_buy") },
        { key: "price_sell", label: this.$t("admin.price_sell") }
      ];
    }
  },

  methods: {
    getData() {
      const requests = [
        this.$http.get(
          `/api/accountant/restaurants/${this.$route.params.restaurant}/dishes`
        )
      ];

      Promise.all(requests).then(responses => {
        this.items = responses[0].data.data;
        this.isLoading = false;
      });
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.restaurants.sections.dishes.title")
    };
  }
};
</script>
